import React from "react"
import { FormattedMessage, useIntl } from "react-intl"

import Img1 from "../images/team/1.png"
import Img2 from "../images/team/2.png"
import Img3 from "../images/team/3.png"
import Img4 from "../images/team/4.png"

const EmployeeData = ({ count, jobType }) => (
  <p className="pr-12 mb-8 md:mb-0">
    <span className="block font-semibold text-xl leading-none">{count}</span>
    <span className="block font-light text-md text-gray-500">{jobType}</span>
  </p>
)

const TeamContent = () => {
  const intl = useIntl()
  return (
    <div>
      <div className="pt-12 md:flex">
        <EmployeeData
          count="46"
          jobType={intl.formatMessage({ id: "team.development" })}
        />
        <EmployeeData
          count="7"
          jobType={intl.formatMessage({ id: "team.management" })}
        />
        <EmployeeData
          count="4"
          jobType={intl.formatMessage({ id: "team.design" })}
        />
        <EmployeeData
          count="3"
          jobType={intl.formatMessage({ id: "team.sales" })}
        />
        <EmployeeData
          count="2"
          jobType={intl.formatMessage({ id: "team.admin" })}
        />
      </div>

      <div className="relative w-0 h-0 mx-auto mt-0 mt-64 md:mt-96 mb-128 md:mb-0 transform scale-50 md:scale-75">
        <img
          src={Img1}
          alt=""
          className="w-auto absolute max-w-none right-0 -bottom-32"
        />
        <img
          src={Img2}
          alt=""
          className="w-auto absolute max-w-none left-0 -bottom-32"
        />
        <img
          src={Img4}
          alt=""
          className="w-auto absolute max-w-none -right-16 top-32"
        />
        <img
          src={Img3}
          alt=""
          className="w-auto absolute max-w-none right-64 top-32"
        />
      </div>

      <div className="ml-auto flex md:w-7/12 justify-center items-center flex-col bg-gray-900 px-4 py-10 md:py-40 md:pr-24 md:pl-40">
        <div className="pl-8 pb-12 w-full">
          <h5 className="text-white font-semibold text-xl">
            <FormattedMessage id="team.regularEvents" />
          </h5>
          <p className="pt-1 text-gray-500 font-light text-sm">
            <FormattedMessage id="team.regularEventsDescription" />
          </p>
        </div>

        <div className="pl-8 pb-12 w-full">
          <h5 className="text-white font-semibold text-xl">
            <FormattedMessage id="team.sportsActivities" />
          </h5>
          <p className="pt-1 text-gray-500 font-light text-sm">
            <FormattedMessage id="team.sportsActivitiesDescription" />
          </p>
        </div>

        <div className="pl-8 w-full">
          <h5 className="text-white font-semibold text-xl">
            <FormattedMessage id="team.fun" />
          </h5>
          <p className="pt-1 text-gray-500 font-light text-sm">
            <FormattedMessage id="team.funDescription" />
          </p>
        </div>
      </div>
    </div>
  )
}

export default TeamContent
