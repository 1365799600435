import React from "react"

const BlockTitle = ({ children, className }) => (
  <div className="flex flex-row items-baseline">
    <div className="w-6 h-6 mr-6 bg-green-300 flex flex-shrink-0"></div>
    <div className={className}>{children}</div>
  </div>
)

export default BlockTitle
