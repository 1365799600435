import React from "react"
import Helmet from "react-helmet"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Content, { HTMLContent } from "../components/Content"
import TeamContent from "../components/TeamContent"
import GetInTouch from "../components/GetInTouch"
import DailyStatistics from "../components/DailyStatistics"

import "../components/Teams.css"

export const TeamPageTemplate = ({
  title,
  content,
  contentComponent,
  getInTouchTitle,
  getInTouchText,
  getInTouchButtonText,
}) => {
  const PageContent = contentComponent || Content

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="container mx-auto pt-10 lg:pt-24 px-10 lg:px-32">
        <div className="flex flex-row">
          <h2 className="font-semibold text-4xl tracking-wide text-gray-900 leading-snug">
            {title}
          </h2>
        </div>
        <div className="flex flex-row">
          <div className="font-light text-lg text-gray-500 mt-2 pt-2 sm:w-7/12 pr-2">
            <PageContent content={content} />
          </div>
        </div>
        <TeamContent />
      </div>
      <DailyStatistics />
      <GetInTouch
        title={getInTouchTitle}
        text={getInTouchText}
        buttonText={getInTouchButtonText}
      />
    </>
  )
}

TeamPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const TeamPage = ({ data, location }) => {
  const { markdownRemark: post } = data

  return (
    <Layout location={location}>
      <TeamPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        getInTouchTitle={post.frontmatter.getInTouchTitle}
        getInTouchText={post.frontmatter.getInTouchText}
        getInTouchButtonText={post.frontmatter.getInTouchButtonText}
      />
    </Layout>
  )
}

TeamPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default TeamPage

export const TeamPageQuery = graphql`
  query TeamPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        getInTouchTitle
        getInTouchText
        getInTouchButtonText
      }
    }
  }
`
