import React from "react"
import RubyIcon from "../images/ruby.svg"
import CoffeeIcon from "../images/coffee.svg"
import PingPongIcon from "../images/ping-pong.svg"
import ChatIcon from "../images/chat.svg"
import InfinityIcon from "../images/infinity.svg"
import BlockTitle from "../components/BlockTitle"
import { FormattedMessage, useIntl } from "react-intl"

import "./Teams.css"

const defaultColClassNames =
  "flex-1 text-gray-700 items-center text-center bg-gray-200 px-4 py-2 m-2"
const defaultItemClassNames = "h-24 mb-6 flex justify-center items-end"
const defaultTitleClassNames = "mb-4 uppercase font-semibold text-xl h-8"
const defaultTextClassNames = "uppercase font-light text-sm"

const BorderLine = () => (
  <div className="md:h-56 md:py-12">
    <span className="item-border h-full block" />
  </div>
)

const ItemWrapper = ({
  imageIcon,
  title,
  text,
  titleIcon,
  imageWrapperClasses,
  imageClasses,
}) => (
  <div className={defaultColClassNames}>
    <div className={imageWrapperClasses || defaultItemClassNames}>
      <img src={imageIcon} alt="" className={imageClasses || "h-20"} />
    </div>
    <h4
      className={
        titleIcon
          ? `${defaultTitleClassNames} flex justify-center items-center`
          : defaultTitleClassNames
      }
    >
      {titleIcon ? <img src={titleIcon} alt="" /> : title}
    </h4>
    <p className={defaultTextClassNames}>{text}</p>
  </div>
)

const DailyStatisticsTitle = () => (
  <div className="text-left md:pl-12 mb-16">
    <BlockTitle className="text-4xl text-gray-900 leading-tight font-semibold">
      <FormattedMessage id="team.dailyStatistics" />
    </BlockTitle>
    <div className="flex flex-row">
      <span className="w-12 h-12" />
      <div className="font-light text-lg text-gray-500 mt-2">
        <FormattedMessage id="team.numbers" />
      </div>
    </div>
  </div>
)

const DailyStatistics = () => {
  const intl = useIntl()

  return (
    <div className="px-20 py-32 bg-gray-200 text-center">
      <DailyStatisticsTitle />
      <div className="md:flex">
        <ItemWrapper
          imageIcon={RubyIcon}
          title="1 000 +"
          text={intl.formatMessage({ id: "team.linesOfCode" })}
        />
        <BorderLine />
        <ItemWrapper
          imageWrapperClasses="h-24 mb-6"
          imageClasses="mx-auto h-24"
          imageIcon={CoffeeIcon}
          title="20 +"
          text={intl.formatMessage({ id: "team.cupsOfCoffee" })}
        />
        <BorderLine />
        <ItemWrapper
          imageIcon={PingPongIcon}
          title="10 +"
          text={intl.formatMessage({ id: "team.pingPongGames" })}
        />
        <BorderLine />
        <ItemWrapper
          imageIcon={ChatIcon}
          titleIcon={InfinityIcon}
          text={intl.formatMessage({ id: "team.qualityConversations" })}
        />
      </div>
    </div>
  )
}

export default DailyStatistics
