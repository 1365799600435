import React, { useContext } from "react"
import PropTypes from "prop-types"
import Button from "./Button"

import "./Teams.css"
import { LanguagePrefixContext } from "./Layout"

const GetInTouch = ({ title, text, buttonText }) => {
  const langPrefix = useContext(LanguagePrefixContext)

  return (
    <div className="join-our-team flex justify-center items-center flex-col py-32 md:px-64 text-center">
      <div className="title font-semibold">{title}</div>
      <div className="text my-10 px-20 mx-2">{text}</div>
      <div className="button">
        <Button to={`${langPrefix}contacts`}>{buttonText}</Button>
      </div>
    </div>
  )
}

GetInTouch.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
}

export default GetInTouch
